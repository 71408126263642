/* You can add global styles to this file, and also import other style files */

.my-background {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  overflow-y: hidden;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

.hide {
  display: none !important;
}
/* Material OverWrites */
.cdk-overlay-pane {
  width: 363px !important;
}
.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-top: 7px !important;
}
.mat-mdc-form-field {
  width: 363px !important;
  height: 42px !important;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
}
.mat-mdc-text-field-wrapper.mdc-text-field {
  border-radius: 10px !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
  color: #000000 !important;
}

.mdc-line-ripple {
  display: none !important;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 4000 !important;
}
.mat-mdc-form-field-infix {
  padding: 0.45rem;
  background-color: #fff !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #fff !important;
  background: 1px solid #e7e7e7 !important;
}

.slick-prev::before,
.slick-next::before {
  color: black !important;
  font-size: 25px !important;
}
.swiper-pagination-bullet {
  width: 2px !important;
  height: 2px !important;
}
.slick-prev {
  left: -17px !important;
}
.slick-next {
  right: -23px !important;
}

.slick-track {
  opacity: 1;
  width: 423px !important;
  display: flex !important;
  transform: translate3d(0px, 0px, 0px);
}
.slick-slide {
  /* width: auto !important; */
  /* width: 267px !important; */
  /* width: fit-content !important;
  position: relative !important; */
}

.slick-slider {
  display: contents !important;
}
/* auto complete */

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 999;
  /*position the autocomplete items to be the same width as the container:*/
  top: 50px;
  left: 0;
  right: 0;
  max-height: 350px;
  overflow-y: scroll;
  border-radius: 4px;
}
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #fecc28 !important;
  cursor: pointer;
}
.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: #fecc28 !important;
  color: black;
}

.slick-track {
  gap: 0.4rem;
}

.hide {
  display: none !important;
}

.group-chat-main p.msg strong {
  color: chocolate;
  font-weight: 600;
}
#emoji-picker-outer emoji-picker {
  position: absolute;
  right: -10px;
  bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .dark-bg {
    background: #00000026 !important;
  }
  #emoji-picker-outer emoji-picker {
    position: absolute;
    right: -40px;
    bottom: 37px;
  }
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-size: 0.8rem !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

image-cropper > div {
  width: 100%;
  position: relative;
  height: max-content;
  min-height: 400px;
  height: 100%;
}
img.source-image {
  max-width: 100%;
  max-height: 100%;
  transform-origin: center;
  position: absolute;
  height: 100%;
  width: 100%;
}
image-cropper .cropper {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
image-cropper .cropper .move {
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f4f4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333;
}

.cr-boundary img {
  max-height: none;
  max-width: none;
}

/* #mat-dialog-0{
    padding: 0px !important;
} */

.cdk-overlay-container .cdk-global-overlay-wrapper .mat-dialog-container {
  padding: 0px !important;
}
a#showmore {
  color: brown;
  font-size: 14px;
}
ngx-file-drop {
  position: absolute !important;
  top: 40px !important;
  width: 100% !important;
  opacity: 0 !important;
  z-index: 9 !important;
}
ngx-file-drop.dragged {
  opacity: 1 !important;
  z-index: 999999999 !important;
  background: #a09a93a6 !important;
}
.ngx-file-drop__drop-zone--over {
  background-color: transparent !important;
}
.ngx-file-drop__drop-zone {
  border: 0px dotted #0782d0 !important;
  border-radius: 0px !important;
  height: 75vh !important;
}
.ngx-file-drop__content {
  height: 75vh !important;
  color: #ce8706 !important;
}
.ngx-file-drop__content .submitBtn {
  position: absolute;
  right: 20px;
  border-radius: 50%;
  bottom: 20px;
}
.ngx-file-drop__content .closeReply {
  position: absolute;
  top: 20px;
  right: 25px !important;
  color: #333 !important;
  font-size: 36px !important;
  bottom: unset !important;
}

@media only screen and (max-width: 820px) {
  .mob-footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #fff;
    width: 100%;
    min-height: 73px;
    position: fixed;
    bottom: 0px;
    box-shadow: 0px 3px 10px 0px #aaa;
  }
  /* .chatsIcon, .feedback-icon {
  position: relative !important;
  width: 52px;
  height: 52px;
  z-index: 999 !important;
  right: unset !important;
  top: unset !important;
  bottom: unset !important;
} */
}

#meet-searchautocomplete-list {
  max-height: 200px !important;
  overflow: auto !important;
}

.ngx-pagination .current {
  background-color: #fecc28 !important;
  color: white !important;
  border-radius: 100% !important;
}

.ngx-pagination a {
  border-radius: 100% !important;
}

.ngx-pagination .pagination-previous,
.ngx-pagination .pagination-next {
  border-radius: none !important;
}

.disabled-div {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.7;
}

.swal2-actions {
  width: 50% !important;
  justify-content: space-around !important;
  display: flex !important;
}

.listing .list-group-item .list-group-item:active {
  margin-top: 0 !important;
  border-top-width: 0 !important;
}

/* .listing a span:active {
  margin-top: 0 !important; 
  border-top-width: 0 !important; 
} */
/* 
.list-group-item.list-group-item:active{
  margin-top: 0 !important;
  border-top-width: 0 !important;
} */

#my-loader {
  position: absolute;
  top: 30%;
  left: 14.9% !important;
}

.calendar-table .calendar .right {
  display: none !important;
}

@media (max-width: 768px) {
  .cdk-overlay-pane , #cdk-overlay-1 {
    width: 330px!important;
    max-width: 330px!important;
  }
}